<template>
  <div>
    <v-row class="pt-5" justify="center">
      <v-col cols="12" lg="11">
        <v-card
          :color="template.modules.control.box"
          :style="`color: ${template.modules.control.box_text};`"
          class="rounded-xl"
        >
          <v-row justify="center">
            <v-col
              cols="12"
              md="6"
              align-self="center"
              class="text-center pa-4"
            >
              <h5 class="text-uppercase font-weight-bold">
                Zuschauerkontrolle
              </h5>
              <h1>
                <v-icon
                  class="mr-2"
                  large
                  :color="template.modules.control.box_text"
                >
                  mdi-stadium
                </v-icon>
                {{ sportstaette.name }}
              </h1>
              <v-divider
                light
                class="my-2"
                v-if="
                  user.data.sportstaetten && user.data.sportstaetten.length > 1
                "
              ></v-divider>
              <v-btn
                text
                :color="template.modules.control.box_text"
                @click="aendere_sportstaette = true"
                v-if="
                  user.data.sportstaetten && user.data.sportstaetten.length > 1
                "
              >
                <v-icon class="mr-2">mdi-swap-horizontal</v-icon>
                Sportstätte wechseln
              </v-btn>
              <v-dialog
                :value="aendere_sportstaette"
                :fullscreen="$vuetify.breakpoint.mdAndDown"
                :persistent="$vuetify.breakpoint.lgAndUp"
                transition="dialog-bottom-transition"
                :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
                dark
                style="padding-top: env(safe-area-inset-top);"
                :retain-focus="false"
                :content-class="
                  $vuetify.breakpoint.mdAndUp ? 'rounded-xl' : undefined
                "
                :overlay-color="template.modules.control.dialog"
                overlay-opacity="0.9"
              >
                <v-card style="width: 100vw; overflow-x: hidden;">
                  <v-toolbar
                    fixed
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    style="
                      padding-top: env(safe-area-inset-top);
                      height: 80px + env(safe-area-inset-top);
                    "
                  >
                    <v-toolbar-title>
                      Sportstätte wechseln
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="aendere_sportstaette = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-row justify="center" v-if="user.data.sportstaetten">
                    <v-col
                      cols="11"
                      class="pa-0 ma-0"
                      v-for="(places, index) in user.data.sportstaetten"
                      :key="index"
                    >
                      <cardbutton
                        :title="places.name"
                        icon="mdi-stadium"
                        :textcolor="template.colors.block_text"
                        :bgcolor="template.colors.blocks"
                        upper
                        chev
                        :to="`/zuschauerkontrolle/${index}`"
                        @click="sportstaette_wechseln(index)"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <v-card
                :color="
                  maxzuschauer_erreicht()
                    ? '#F44336'
                    : template.modules.control.box_secondary
                "
                :style="
                  maxzuschauer_erreicht()
                    ? `color: #FFFFFF;`
                    : `color: ${template.modules.control.box_secondary_text};`
                "
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'rounded-tr-xl rounded-bl-xl'
                    : 'my-2'
                "
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-4 px-4 text-center">
                    <v-row justify="center" class="py-0">
                      <v-col cols="auto" align-self="center">
                        <h4 class="text-center text-uppercase">
                          Anwesende Personen
                        </h4>
                      </v-col>
                      <v-col cols="auto" align-self="center">
                        <v-btn
                          icon
                          :color="
                            maxzuschauer_erreicht()
                              ? '#F44336'
                              : template.modules.control.box_secondary_text
                          "
                          :loading="aktualisiere_personenzahl"
                          @click="check_current_time_once()"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pt-0 mt-0 px-4 text-center">
                    <h1 class="text-center" v-if="!aktualisiere_personenzahl">
                      {{ get_derzeit_anwesende_personen() }}
                      <small class="font-weight-light">
                        /{{
                          sportstaette.maxzuschauer
                            ? sportstaette.maxzuschauer
                            : this.user.data.maxzuschauer
                        }}
                      </small>
                    </h1>
                    <v-progress-circular
                      :width="4"
                      :color="
                        maxzuschauer_erreicht()
                          ? '#F44336'
                          : template.colors.inline_secondary_text
                      "
                      indeterminate
                      v-if="aktualisiere_personenzahl"
                      class="my-2"
                    ></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <cardbutton
                      :title="'Personen verwalten'"
                      :icon="'mdi-clipboard-list'"
                      :textcolor="template.modules.control.buttons_text"
                      :bgcolor="template.modules.control.buttons"
                      upper
                      :disabled="anwesende_personen.length == 0"
                      :addclass="
                        $vuetify.breakpoint.mdAndUp
                          ? 'rounded-bl-xl mb-0'
                          : 'mb-0'
                      "
                      @click="personenverwalten = true"
                    />
                    <personenverwaltung
                      v-if="personenverwalten"
                      v-on:closed="personenverwalten = false"
                      :personen="anwesende_personen"
                      :veranstaltungen="veranstaltungen"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="text-center pb-0" align-self="end">
              <v-card
                :color="template.modules.control.buttons"
                :style="`color: ${template.modules.control.buttons_text};`"
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'rounded-tr-xl rounded-bl-xl pb-0'
                    : 'my-2'
                "
              >
                <v-row justify="center">
                  <v-col cols="12" class="pb-0">
                    <v-card
                      color="rgba(0,0,0,0)"
                      elevation="0"
                      @click="
                        newzuschauer = true
                        zs.type = 'person'
                      "
                    >
                      <v-icon large class="mr-2">mdi-account-plus</v-icon>
                      Neue Person erfassen
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-card
                      color="rgba(0,0,0,0)"
                      elevation="0"
                      @click="
                        newzuschauer = true
                        zs.type = 'verein'
                      "
                    >
                      <v-icon large class="mr-2">mdi-shield-account</v-icon>
                      Vereins-Person erfassen
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="false">
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12" class="pt-0" v-if="false">
                    <v-card
                      color="rgba(0,0,0,0)"
                      elevation="0"
                      @click="
                        newzuschauer = true
                        zs.type = 'qrcode'
                      "
                    >
                      <v-icon large class="mr-2">mdi-qrcode</v-icon>
                      CheckIn-Karte scannen
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="text-center pa-4">
              <h3>AKTUELLE VERANSTALTUNGEN</h3>
              <small>
                Am
                <b>{{ this.timer.datum_format }}</b>
                um
                <b>{{ this.timer.uhrzeit_format_sec }} Uhr</b>
              </small>
              <v-row justify="center" v-if="aktualisiere_veranstaltungen">
                <v-col cols="12" class="text-center">
                  <v-progress-circular
                    :width="5"
                    :color="template.colors.block_text"
                    indeterminate
                    class="my-2"
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row
                justify="center"
                class="pt-0"
                v-if="!aktualisiere_veranstaltungen"
              >
                <v-col
                  cols="12"
                  class="pt-0"
                  v-for="event in veranstaltungen"
                  :key="event.id"
                >
                  <cardveranstaltung
                    v-if="
                      event.id != 'ANDERE' &&
                      event.beginn.uhrzeit <= timer.uhrzeit
                    "
                    :abgesagt="event.abgesagt"
                    :art="event.art"
                    :gastmannschaft="event.gastmannschaft"
                    :id="event.id"
                    :mannschaft="event.mannschaft"
                    :name="event.name"
                    :beginnuhrzeit="event.beginn.uhrzeit"
                    :beginndatum="event.beginn.datum"
                  />
                </v-col>
              </v-row>
              <p class="mt-4" v-if="veranstaltungen.length <= 1">
                Derzeit sind keine Veranstaltungen angelegt, die auf dieser
                Sportanlage stattfinden.
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      :value="newzuschauer"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :persistent="$vuetify.breakpoint.lgAndUp"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
      dark
      style="padding-top: env(safe-area-inset-top);"
      :retain-focus="false"
      :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : undefined"
      :overlay-color="template.modules.control.dialog"
      overlay-opacity="0.9"
    >
      <v-card style="width: 100vw; overflow-x: hidden;">
        <v-toolbar
          fixed
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          style="
            padding-top: env(safe-area-inset-top);
            height: 80px + env(safe-area-inset-top);
          "
        >
          <v-toolbar-title v-if="zs.type == 'person'">
            Neue Person erfassen
          </v-toolbar-title>
          <v-toolbar-title v-if="zs.type == 'qrcode'">
            CheckIn-Karte scannen
          </v-toolbar-title>
          <v-toolbar-title v-if="zs.type == 'verein'">
            Vereins-Person erfassen
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closing()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row justify="center" class="pa-4" v-if="!zs.veranstaltung">
          <v-col cols="12" class="text-center">
            <h3>
              Bitte wähle die Veranstaltung, für die die Person erfasst werden
              soll:
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="5"
            v-for="event in veranstaltungen"
            :key="event.id"
          >
            <v-card
              class="rounded-xl"
              :elevation="8"
              :color="
                event.abgesagt
                  ? 'rgba(255,255,255,0.5)'
                  : template.colors.blocks
              "
              :style="`color: ${template.colors.block_text}; height: 100%;`"
              link
              @click="zs.veranstaltung = event"
            >
              <v-row class="px-2">
                <v-col cols="2" align-self="center">
                  <v-avatar class="my-auto">
                    <v-icon
                      :class="
                        event.abgesagt
                          ? 'grey darken-3 error--text'
                          : 'grey lighten-1'
                      "
                    >
                      {{
                        event.art == 'Training'
                          ? 'mdi-strategy'
                          : event.art == 'Wettkampf'
                          ? 'mdi-podium'
                          : event.art == 'Freundschaftsspiel'
                          ? 'mdi-soccer'
                          : event.art == 'Auswärtsspiel'
                          ? 'mdi-rocket-launch'
                          : event.art == 'Pokalspiel'
                          ? 'mdi-trophy-variant'
                          : event.art == 'Versammlung'
                          ? 'mdi-calendar-star'
                          : 'mdi-soccer-field'
                      }}
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="8"
                  align-self="center"
                  v-if="event.art == 'Andere'"
                >
                  <span>
                    <b>{{ event.name }}</b>
                  </span>
                </v-col>
                <v-col
                  cols="8"
                  align-self="center"
                  v-if="event.art != 'Andere'"
                >
                  <span>
                    <b>
                      {{
                        event.art == 'Training'
                          ? event.mannschaft
                          : event.art == 'Wettkampf' ||
                            event.art == 'Versammlung'
                          ? event.name
                          : event.mannschaft + ' vs ' + event.gastmannschaft
                      }}
                    </b>
                    <br
                      v-if="event.art != 'Training' && event.art != 'Wettkampf'"
                    />
                    {{
                      event.art == 'Training' || event.art == 'Wettkampf'
                        ? ' - '
                        : ''
                    }}
                    {{
                      event.art == 'Versammlung' ? event.mannschaft : event.art
                    }}
                  </span>
                </v-col>
                <v-col cols="2" align-self="center">
                  <v-icon large :color="template.colors.block_text">
                    mdi-chevron-right
                  </v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="5"
            v-for="event in future_veranstaltungen"
            :key="event.id"
          >
            <v-card
              class="rounded-xl"
              :elevation="8"
              :color="
                event.abgesagt
                  ? 'rgba(255,255,255,0.5)'
                  : template.colors.blocks
              "
              :style="`color: ${template.colors.block_text}; height: 100%;`"
              link
              @click="zs.veranstaltung = event"
            >
              <v-row class="px-2">
                <v-col cols="2" align-self="center">
                  <v-avatar class="my-auto">
                    <v-icon
                      :class="
                        event.abgesagt
                          ? 'grey darken-3 error--text'
                          : 'grey lighten-1'
                      "
                    >
                      {{
                        event.art == 'Training'
                          ? 'mdi-strategy'
                          : event.art == 'Wettkampf'
                          ? 'mdi-podium'
                          : event.art == 'Freundschaftsspiel'
                          ? 'mdi-soccer'
                          : event.art == 'Auswärtsspiel'
                          ? 'mdi-rocket-launch'
                          : event.art == 'Pokalspiel'
                          ? 'mdi-trophy-variant'
                          : event.art == 'Versammlung'
                          ? 'mdi-calendar-star'
                          : 'mdi-soccer-field'
                      }}
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="8"
                  align-self="center"
                  v-if="event.art == 'Andere'"
                >
                  <span>
                    <b>{{ event.name }}</b>
                  </span>
                </v-col>
                <v-col
                  cols="8"
                  align-self="center"
                  v-if="event.art != 'Andere'"
                >
                  <span>
                    <b>
                      {{
                        event.art == 'Training'
                          ? event.mannschaft
                          : event.art == 'Wettkampf' ||
                            event.art == 'Versammlung'
                          ? event.name
                          : event.mannschaft + ' vs ' + event.gastmannschaft
                      }}
                    </b>
                    <br
                      v-if="event.art != 'Training' && event.art != 'Wettkampf'"
                    />
                    {{
                      event.art == 'Training' || event.art == 'Wettkampf'
                        ? ' - '
                        : ''
                    }}
                    {{
                      event.art == 'Versammlung' ? event.mannschaft : event.art
                    }}
                    <small>BEGINN: {{ event.beginn.uhrzeit }} UHR</small>
                  </span>
                </v-col>
                <v-col cols="2" align-self="center">
                  <v-icon large :color="template.colors.block_text">
                    mdi-chevron-right
                  </v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          lazy-validation
          v-if="zs.veranstaltung && zs.type != 'verein'"
        >
          <v-row justify="center" class="mt-4" v-if="zs.type == 'person'">
            <v-col cols="10" lg="8" class="px-2">
              <v-select
                filled
                rounded
                v-model="zs.veranstaltung"
                :items="veranstaltungen_select"
                item-text="name"
                label="Veranstaltung"
                :rules="[rules.required]"
              ></v-select>
              <v-text-field
                filled
                rounded
                label="Vorname"
                v-model="zs.vorname"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Name"
                v-model="zs.name"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Straße + Nr"
                v-model="zs.adresse"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="PLZ"
                v-model="zs.plz"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Ort"
                v-model="zs.ort"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Telefon"
                v-model="zs.telefon"
              ></v-text-field>
              <v-select
                filled
                rounded
                :items="aktivitaeten"
                label="Aktivität"
                v-model="zs.aktivitaet"
                v-if="aktivitaeten.length > 0"
              ></v-select>
              <v-select
                filled
                rounded
                :items="zeiten"
                label="Dauer der Anwesenheit"
                item-text="label"
                item-value="time"
                v-model="zeitwahl"
                v-if="zs.veranstaltung.id == 'ANDERE'"
              ></v-select>

              <ggswitches
                :geimpft="false"
                :genesen="false"
                :getestet="false"
                :datum="false"
                @valid="validate_ggg($event)"
                @change="get_ggg_data($event)"
              ></ggswitches>
              <v-checkbox
                label="Diese Person dauerhaft in Vereinsdatenbank speichern."
                v-model="zs.save_person"
              ></v-checkbox>
              <div v-if="zs.save_person">
                <span class="font-weight-bold">
                  Mit meiner Unterschrift bestätige ich, dass ich
                  {{ zs.vorname }} {{ zs.name }} die
                  <a
                    :href="
                      template.datenschutz
                        ? template.datenschutz.link
                        : '/datenschutz'
                    "
                    target="_blank"
                  >
                    Datenschutzinformationen
                  </a>
                  zur Kenntnis genommen habe.
                </span>
                <v-card :color="template.colors.blocks" class="rounded-xl">
                  <VueSignaturePad
                    width="100%"
                    height="300px"
                    ref="signaturePad"
                  />
                </v-card>
              </div>
              <v-checkbox
                v-model="accept_privacy"
                label="Ich bestätige, dass ich von dem Inhaber der eingegeben Daten zur Speicherung der Daten autorisiert wurde und diesem die Datenschutzinformation zur Kenntnis gesetzt wurde."
              ></v-checkbox>
              <v-btn
                class="my-4"
                block
                large
                rounded
                :disabled="!accept_privacy || testpflicht || !ggg_valid"
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="zuschauer_erfassen()"
                :loading="creating"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                Hinzufügen
              </v-btn>
              <v-btn
                class="my-4"
                block
                large
                rounded
                outlined
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="newzuschauer = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4" v-if="zs.type == 'qrcode'">
            <v-col
              cols="10"
              lg="8"
              class="px-2 text-center"
              v-if="!qrcodedata && camera_loading"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <h3 class="mt-3">Kamera wird geladen</h3>
            </v-col>
            <v-col cols="12" lg="8" class="px-2" v-if="!qrcodedata">
              <qrcode-stream @detect="onDetect" @init="onInit" />
            </v-col>
            <v-col cols="10" lg="8" class="px-2" v-if="qrcodedata">
              <v-text-field
                filled
                rounded
                label="Vorname"
                v-model="zs.vorname"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Name"
                v-model="zs.name"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Straße + Nr"
                v-model="zs.adresse"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="PLZ"
                v-model="zs.plz"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Ort"
                v-model="zs.ort"
              ></v-text-field>
              <v-text-field
                filled
                rounded
                label="Telefon"
                v-model="zs.telefon"
              ></v-text-field>
              <v-select
                filled
                rounded
                :items="zeiten"
                label="Dauer der Anwesenheit"
                item-text="label"
                item-value="time"
                v-model="zeitwahl"
                v-if="zs.veranstaltung.id == 'ANDERE'"
              ></v-select>

              <v-btn
                class="my-4"
                block
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="zuschauer_erfassen()"
                :loading="creating"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                Hinzufügen
              </v-btn>
              <v-btn
                class="my-4"
                block
                large
                outlined
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="newzuschauer = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row justify="center" v-if="zs.veranstaltung && zs.type == 'verein'">
          <v-col
            cols="10"
            class="pb-0"
            v-if="!teamselection || teamselection == 'DB'"
          >
            <cardbutton
              title="Zuschauer-Datenbank"
              :vereinslogo="user.data.verein.logo"
              :verein="user.data.verein.name"
              :textcolor="
                !teamselection
                  ? template.colors.block_text
                  : template.colors.inline_primary_text
              "
              :bgcolor="
                !teamselection
                  ? template.colors.blocks
                  : template.colors.primary
              "
              upper
              :chev="!teamselection"
              @click="select_team('DB')"
            />
          </v-col>
          <v-col cols="7" md="6">
            <v-text-field
              v-if="teamselection == 'DB'"
              solo
              rounded
              light
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Suche"
              v-model="db_search"
              @input="search_database()"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="4" v-if="teamselection">
            <v-select
              filled
              rounded
              label="Sortierung"
              :items="sort_auswahl"
              v-model="sortierung"
            ></v-select>
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-row justify="center" class="pt-0">
              <v-col
                cols="12"
                class="py-1"
                v-for="(t, index) in teams"
                :key="index"
              >
                <cardbutton
                  :title="t.name"
                  :vereinslogo="user.data.verein.logo"
                  :verein="user.data.verein.name"
                  :textcolor="
                    !teamselection
                      ? template.colors.block_text
                      : template.colors.inline_primary_text
                  "
                  :bgcolor="
                    !teamselection
                      ? template.colors.blocks
                      : template.colors.primary
                  "
                  upper
                  :chev="!teamselection"
                  @click="select_team(t.id)"
                  v-if="!teamselection || teamselection == t.id"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
                v-for="(member, index) in searchmembers"
                :key="member.id"
                class="py-0"
              >
                <cardbutton
                  :disabled="
                    ((gg_regel || ggplus_regel) &&
                      !member.geimpft &&
                      !member.genesen) ||
                    member.anwesend
                      ? true
                      : false
                  "
                  :title="
                    member.vorname +
                    ' ' +
                    member.name +
                    (member.aktivitaet && member.aktivitaet != 'Zuschauer'
                      ? ' (' + member.aktivitaet + ')'
                      : '')
                  "
                  :ggg="
                    (testpflicht_required || gg_regel || ggplus_regel) &&
                    !member.anwesend
                      ? member.geimpft
                        ? 'Vollständig geimpft'
                        : member.genesen
                        ? 'Vollständig gensen'
                        : undefined
                      : member.anwesend
                      ? 'BEREITS ANWESEND'
                      : undefined
                  "
                  :icon="member.selected ? 'mdi-check-bold' : 'mdi-account'"
                  :textcolor="template.colors.block_text"
                  :bgcolor="
                    member.selected ? 'success' : template.colors.blocks
                  "
                  upper
                  :loading="generating"
                  @click="select_member(index)"
                />
              </v-col>
              <v-col
                cols="12"
                v-if="teamselection && zs.veranstaltung.id == 'ANDERE'"
                class="text-center pt-3"
              >
                <v-select
                  filled
                  hide-details
                  rounded
                  :items="zeiten"
                  label="Dauer der Anwesenheit"
                  item-text="label"
                  item-value="time"
                  v-model="zeitwahl"
                  v-if="zs.veranstaltung.id == 'ANDERE'"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="teamselection" class="text-center pt-5">
                <v-btn
                  x-large
                  class="rounded-xl"
                  :color="
                    selected_members.length == 0
                      ? template.colors.blocks
                      : template.colors.primary
                  "
                  :outlined="selected_members.length == 0"
                  block
                  :disabled="
                    testpflicht ||
                    (zs.veranstaltung.id == 'ANDERE' &&
                      zeitwahl.stunden == 0 &&
                      zeitwahl.minuten == 0) ||
                    selected_members.length == 0 ||
                    !zeitwahl
                  "
                  @click="add_vereinsmember()"
                >
                  {{
                    zs.veranstaltung.id == 'ANDERE' &&
                    zeitwahl.stunden == 0 &&
                    zeitwahl.minuten == 0
                      ? 'DAUER DER ANWESENHEIT WÄHLEN'
                      : selected_members.length == 0
                      ? 'KEINE PERSON AUSGEWÄHLT'
                      : selected_members.length > 1
                      ? selected_members.length + ' PERSONEN ERFASSEN'
                      : selected_members.length + ' PERSON ERFASSEN'
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="duplicate_zuschauer.active"
      persistent
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '600' : '500'"
      dark
      content-class="rounded-xl"
      :overlay-color="template.modules.control.dialog"
      overlay-opacity="0.9"
    >
      <v-card class="rounded-xl">
        <v-card-title>PERSON BEREITS ANWESEND</v-card-title>
        <v-card-text>
          Es ist bereits eine Person mit dem Namen
          {{ duplicate_zuschauer.vorname }}
          {{ duplicate_zuschauer.name }} anwesend. Möchten Sie die Anwesenheit
          trotzdem erfassen?
        </v-card-text>
        <v-card-actions>
          <v-btn text :color="template.colors.danger" @click="closing()">
            Abbrechen
          </v-btn>
          <v-btn
            text
            :color="template.colors.success"
            @click="
              accept_duplicate_zuschauer = true
              zuschauer_erfassen()
            "
          >
            Bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cardbutton from '../../../elements/cards/card-button'
import cardveranstaltung from '../../../elements/cards/card-veranstaltung'
import personenverwaltung from './module/personen_verwalten'
import { mapGetters } from 'vuex'
import { QrcodeStream } from 'vue-qrcode-reader'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import ggswitches from '../2G-Regel/switches.vue'

export default {
  name: 'Start',
  data() {
    return {
      zs: {
        veranstaltung: '',
        type: '',
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
        aktivitaet: 'Zuschauer',
        weitere_personen: [],
        save_person: false,
      },
      aktivitaeten: [],
      newveranstaltung: false,
      newzuschauer: false,
      personenverwalten: false,
      veranstaltungen: [],
      future_veranstaltungen: [],
      veranstaltungen_select: [],
      veranst_date: [],
      future_dates: [],
      past_dates: [],
      todays_dates: [],
      teams: [],
      accept_privacy: false,
      db_search: '',
      teamselection: '',
      team_members: [],
      search_members: [],
      selected_members: [],
      anwesende_personen: [],
      anwesende_member: [],
      aendere_sportstaette: false,
      zeiten: [
        {
          label: '15 Minuten',
          time: { hours: 0, minutes: 15 },
        },
        {
          label: '30 Minuten',
          time: { hours: 0, minutes: 30 },
        },
        {
          label: '1 Stunde',
          time: { hours: 1, minutes: 0 },
        },
        {
          label: '1.5 Stunden',
          time: { hours: 1, minutes: 30 },
        },
        {
          label: '2 Stunden',
          time: { hours: 2, minutes: 0 },
        },
        {
          label: '3 Stunden',
          time: { hours: 3, minutes: 0 },
        },
        {
          label: '4 Stunden',
          time: { hours: 4, minutes: 0 },
        },
        {
          label: '5 Stunden',
          time: { hours: 5, minutes: 0 },
        },
        {
          label: '6 Stunden',
          time: { hours: 6, minutes: 0 },
        },
        {
          label: '7 Stunden',
          time: { hours: 7, minutes: 0 },
        },
        {
          label: '8 Stunden',
          time: { hours: 8, minutes: 0 },
        },
        {
          label: '9 Stunden',
          time: { hours: 9, minutes: 0 },
        },
        {
          label: '10 Stunden',
          time: { hours: 10, minutes: 0 },
        },
        {
          label: '11 Stunden',
          time: { hours: 11, minutes: 0 },
        },
        {
          label: '12 Stunden',
          time: { hours: 12, minutes: 0 },
        },
      ],
      zeitwahl: {
        stunden: 0,
        minuten: 0,
      },
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      ggg_valid: false,
      newteam: false,
      sportstaette: '',
      qrcodedata: false,
      date: '',
      datum: '',
      current_time: '',
      tabs: 1,
      add_to_home: false,
      multidelete: false,
      notify: false,
      camera_loading: true,
      generating: false,
      creating: false,
      duplicate_zuschauer: {
        active: false,
        vorname: '',
        name: '',
      },
      accept_duplicate_zuschauer: false,
      aktualisiere_personenzahl: false,
      aktualisiere_veranstaltungen: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
      },
      listeners: {
        anwesenheit: '',
        veranstaltungen: '',
      },
      sortierung: 'Name (aufsteigend)',
      sort_auswahl: [
        'Name (aufsteigend)',
        'Name (absteigend)',
        'Vorname (aufsteigend)',
        'Vorname (absteigend)',
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
    testpflicht() {
      if (this.user.data) {
        if (this.user.data.hygienekonzept) {
          if (this.user.data.hygienekonzept.testpflicht) {
            if (this.selected_members.length > 0) {
              var alle_getestet = true
              this.selected_members.forEach((member) => {
                if (!member.genesen && !member.geimpft) {
                  alle_getestet = false
                }
              })
              if (!alle_getestet) {
                if (
                  !this.ggg.getestet &&
                  !this.ggg.genesen &&
                  !this.ggg.geimpft
                ) {
                  return true
                }
              }
            } else if (
              !this.ggg.getestet &&
              !this.ggg.genesen &&
              !this.ggg.geimpft
            ) {
              return true
            }
          }
        }
      }
      return false
    },
    testpflicht_required() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.testpflicht) {
          if (!this.user.data.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
    searchmembers() {
      var arr = this.search_members
      function compareNameASC(a, b) {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.name > b.name) {
          return -1
        } else {
          return 1
        }
      }
      function compareVornameASC(a, b) {
        if (a.vorname > b.vorname) {
          return 1
        } else {
          return -1
        }
      }
      function compareVornameDESC(a, b) {
        if (a.vorname > b.vorname) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Name (aufsteigend)') {
        arr.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        arr.sort(compareNameDESC)
      }
      if (this.sortierung == 'Vorname (aufsteigend)') {
        arr.sort(compareVornameASC)
      }
      if (this.sortierung == 'Vorname (absteigend)') {
        arr.sort(compareVornameDESC)
      }
      return arr
    },
  },
  mounted() {
    store.dispatch('setTemplateBackgroundGradient', {
      start: '25%',
      end: '80%',
    })
    if (this.$route.params.sportstaette) {
      this.sportstaette = this.user.data.sportstaetten[
        this.$route.params.sportstaette
      ]
    } else {
      this.sportstaette = this.user.data.sportstaetten[0]
    }
    if (
      this.user.data.rolle == 'Vorstand' ||
      this.user.data.rolle == 'Trainer' ||
      this.user.data.rolle == 'Zuschauerkontrolle'
    ) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .where('beginn.datum', '==', this.timer.datum)
        .onSnapshot(
          (querySnap) => {
            this.aktualisiere_veranstaltungen = true
            this.anwesende_personen = []
            this.veranstaltungen = []
            this.veranstaltungen.push({
              id: 'ANDERE',
              art: 'Andere',
              name: 'Andere Veranstaltung',
              anwesende_personen: 0,
            })
            querySnap.forEach((doc) => {
              if (
                doc.data().sportstaette == this.sportstaette.name ||
                (this.sportstaette.main && !doc.data().sportstaette)
              ) {
                this.veranstaltungen.push(doc.data())
                var index = this.veranstaltungen.length - 1
                this.veranstaltungen[index].id = doc.id
                this.veranstaltungen[index].personen = []
                if (!doc.data().anwesende_personen) {
                  this.veranstaltungen[index].anwesende_personen = 0
                }
              }
            })
            setTimeout(() => {
              this.aktualisiere_veranstaltungen = false
            }, 1500)
          },
          (error) => {
            console.log(error)
          },
        )
      this.check_current_time_once()

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .onSnapshot((querySnap) => {
          this.teams = []
          querySnap.forEach((doc) => {
            this.teams.push(doc.data())
            var index = this.teams.length - 1
            this.teams[index].id = doc.id
          })
        })

      if (
        this.user.data.aktivitaeten.length >
        this.template.var.standard_aktivitaeten.length
      ) {
        this.user.data.aktivitaeten.forEach((aktivitaet) => {
          if (
            !this.template.var.standard_aktivitaeten.includes(aktivitaet) ||
            this.template.var.public_aktivitaeten.includes(aktivitaet)
          ) {
            this.aktivitaeten.push(aktivitaet)
          }
        })
      }

      setTimeout(() => {
        this.check_current_time()
      }, 500)
    }
  },
  components: {
    cardbutton,
    cardveranstaltung,
    QrcodeStream,
    personenverwaltung,
    ggswitches,
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    maxzuschauer_erreicht() {
      if (this.sportstaette.maxzuschauer) {
        if (
          this.get_derzeit_anwesende_personen() > this.sportstaette.maxzuschauer
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          this.get_derzeit_anwesende_personen() > this.user.data.maxzuschauer
        ) {
          return true
        } else {
          return false
        }
      }
    },
    addNull(num) {
      if (num >= 10) {
        return num
      } else {
        return '0' + num
      }
    },
    calc_ende() {
      var datum = new Date()
      datum.setHours(datum.getHours() + this.zeitwahl.hours)
      datum.setMinutes(datum.getMinutes() + this.zeitwahl.minutes)
      var ende = {
        datum:
          datum.getFullYear() +
          '-' +
          this.addNull(datum.getMonth() + 1) +
          '-' +
          this.addNull(datum.getDate()),
        uhrzeit:
          this.addNull(datum.getHours()) +
          ':' +
          this.addNull(datum.getMinutes()) +
          ':' +
          this.addNull(datum.getSeconds()),
        timestamp: datum.getTime(),
      }
      if (ende.datum > this.timer.datum) {
        datum.setHours(23, 59, 59)
      }
      var endeObj = new Zeit()
      endeObj = endeObj.setFromObject(datum)
      return endeObj
    },
    sportstaette_wechseln(id) {
      this.sportstaette = this.user.data.sportstaetten[id]
      this.check_current_time_once()
      this.aendere_sportstaette = false
    },
    add_vereinsmember() {
      var veranstaltung = {
        id: 'ANDERE',
        art: 'Andere',
        name: 'Andere Veranstaltung',
      }
      var endzeit = {
        datum: '',
        uhrzeit: '',
      }
      if (this.zs.veranstaltung.id != 'ANDERE') {
        veranstaltung = this.zs.veranstaltung
        endzeit = new Zeit(
          this.zs.veranstaltung.ende.datum,
          this.zs.veranstaltung.ende.uhrzeit,
        )
      } else {
        endzeit = this.calc_ende()
      }
      var zutritt = new Zeit()
      for (let i = 0; i < this.selected_members.length; i++) {
        var exists = false
        for (let j = 0; j < this.anwesende_personen.length; j++) {
          if (
            this.selected_members[i].vorname ==
              this.anwesende_personen[j].person.vorname &&
            this.selected_members[i].name ==
              this.anwesende_personen[j].person.name &&
            this.selected_members[i].adresse ==
              this.anwesende_personen[j].person.adresse &&
            this.selected_members[i].telefon ==
              this.anwesende_personen[j].person.telefon
          ) {
            exists = true
          }
        }
        if (!exists) {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Anwesenheit')
            .add({
              person: {
                id: this.selected_members[i].id,
                team: this.selected_members[i].team,
                vorname: this.selected_members[i].vorname.trim(),
                name: this.selected_members[i].name.trim(),
                adresse: this.selected_members[i].adresse.trim(),
                plz: this.selected_members[i].plz.trim(),
                ort: this.selected_members[i].ort.trim(),
                telefon: this.selected_members[i].telefon.trim(),
              },
              ggg: this.testpflicht_required
                ? this.selected_members[i].geimpft
                  ? {
                      getestet: false,
                      geimpft: true,
                      genesen: false,
                    }
                  : this.selected_members[i].genesen
                  ? { getestet: false, geimpft: false, genesen: true }
                  : this.ggg
                : '',
              zutritt: zutritt.toJSON(),
              weitere_personen: this.zs.weitere_personen,
              verlassen: endzeit.toJSON(),
              sportstaette: this.sportstaette,
              veranstaltung: veranstaltung,
              aktivitaet: this.selected_members[i].aktivitaet
                ? this.selected_members[i].aktivitaet
                : 'Zuschauer',
            })
            .then(() => {
              if (i + 1 == this.selected_members.length) {
                this.closing()
                this.check_current_time_once()
              }
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          if (i + 1 == this.selected_members.length) {
            this.closing()
            this.check_current_time_once()
          }
        }
      }
    },
    select_member(index) {
      this.team_members[index].selected
        ? (this.team_members[index].selected = false)
        : (this.team_members[index].selected = true)
      var new_team_members = this.team_members
      this.team_members = []
      this.selected_members = []
      for (let i = 0; i < new_team_members.length; i++) {
        this.team_members.push(new_team_members[i])
        if (new_team_members[i].selected) {
          this.selected_members.push(new_team_members[i])
        }
      }
    },
    search_database() {
      if (this.db_search) {
        this.search_members = []
        this.team_members.forEach((member) => {
          if (
            member.vorname.includes(this.db_search) ||
            member.name.includes(this.db_search)
          ) {
            this.search_members.push(member)
          } else if (member.aktivitaet) {
            if (member.aktivitaet.includes(this.db_search)) {
              this.search_members.push(member)
            }
          }
        })
      } else {
        this.search_members = this.team_members
      }
    },
    select_team(team) {
      if (team != 'DB') {
        this.teamloading = team
        this.teamselection = team
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Team')
          .doc(team)
          .collection('Mitglieder')
          .get()
          .then((snap) => {
            this.team_members = []
            snap.forEach((doc) => {
              this.team_members.push(doc.data())
              var index = this.team_members.length - 1
              this.team_members[index].id = doc.id
              this.team_members[index].team = team
              if (this.anwesende_member.includes(team + '-' + doc.id)) {
                this.team_members[index].anwesend = true
              }
            })
            this.teamloading = false
            this.search_members = this.team_members
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.teamloading = team
        this.teamselection = team
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Personen')
          .get()
          .then((querySnap) => {
            this.team_members = []
            querySnap.forEach((doc) => {
              this.team_members.push(doc.data())
              var index = this.team_members.length - 1
              this.team_members[index].id = doc.id
              this.team_members[index].team = 'DB'
              if (this.anwesende_member.includes('DB-' + doc.id)) {
                this.team_members[index].anwesend = true
              }
            })
            this.teamloading = false
            this.search_members = this.team_members
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    check_current_time() {
      if (this.timer.sekunden == '00' || this.timer.sekunden == '30') {
        this.check_current_time_once()
        setTimeout(() => {
          this.check_current_time()
        }, 30000)
      } else {
        setTimeout(() => {
          this.check_current_time()
        }, 500)
      }
    },
    check_current_time_once() {
      if (!this.aktualisiere_personenzahl) {
        this.aktualisiere_personenzahl = true
        this.aktualisiere_veranstaltungen = true
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .where('beginn.datum', '==', this.timer.datum)
          .where('ende.uhrzeit', '>', this.timer.uhrzeit)
          .get()
          .then(
            (querySnap) => {
              this.anwesende_personen = []
              this.veranstaltungen = []
              this.future_veranstaltungen = []
              this.veranstaltungen.push({
                id: 'ANDERE',
                art: 'Andere',
                name: 'Andere Veranstaltung',
                anwesende_personen: 0,
              })
              querySnap.forEach((doc) => {
                if (
                  doc.data().art != 'Auswärtsspiel' &&
                  doc.data().beginn.uhrzeit <= this.timer.uhrzeit &&
                  (doc.data().sportstaette == this.sportstaette.name ||
                    (this.sportstaette.main && !doc.data().sportstaette))
                ) {
                  this.veranstaltungen.push(doc.data())
                  var index = this.veranstaltungen.length - 1
                  this.veranstaltungen[index].id = doc.id
                  this.veranstaltungen[index].personen = []
                  if (!doc.data().name) {
                    var va = doc.data()
                    if (va.art == 'Training') {
                      this.veranstaltungen[index].name =
                        va.art + ' (' + va.mannschaft + ')'
                    }
                    if (va.art != 'Training') {
                      this.veranstaltungen[index].name =
                        va.art +
                        ' (' +
                        va.mannschaft +
                        ' ' +
                        va.gastmannschaft +
                        ')'
                    }
                  }
                  if (!doc.data().anwesende_personen) {
                    this.veranstaltungen[index].anwesende_personen = 0
                  }
                } else if (
                  doc.data().art != 'Auswärtsspiel' &&
                  (doc.data().sportstaette == this.sportstaette.name ||
                    (this.sportstaette.main && !doc.data().sportstaette))
                ) {
                  this.future_veranstaltungen.push(doc.data())
                  var index1 = this.future_veranstaltungen.length - 1
                  this.future_veranstaltungen[index1].id = doc.id
                  this.future_veranstaltungen[index1].personen = []
                  if (!doc.data().name) {
                    var va1 = doc.data()
                    if (va1.art == 'Training') {
                      this.future_veranstaltungen[index1].name =
                        va1.art + ' (' + va1.mannschaft + ')'
                    }
                    if (va1.art != 'Training') {
                      this.future_veranstaltungen[index1].name =
                        va1.art +
                        ' (' +
                        va1.mannschaft +
                        ' ' +
                        va1.gastmannschaft +
                        ')'
                    }
                  }
                }
              })
              this.aktualisiere_veranstaltungen = false
              this.veranstaltungen_select = this.veranstaltungen.concat(
                this.future_veranstaltungen,
              )
            },
            (error) => {
              console.log(error)
            },
          )
          .then(() => {
            if (this.listeners.anwesenheit) {
              this.listeners.anwesenheit()
            }
            this.listeners.anwesenheit = firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Anwesenheit')
              .where('verlassen.timestamp', '!=', Date.now())
              .onSnapshot((querySnap) => {
                this.anwesende_personen = []
                this.anwesende_member = []
                querySnap.forEach((doc) => {
                  console.log(doc.data())
                  if (
                    ((doc.data().zutritt.datum <= this.timer.datum &&
                      doc.data().zutritt.uhrzeit <= this.timer.uhrzeit &&
                      doc.data().verlassen.datum >= this.timer.datum &&
                      doc.data().verlassen.uhrzeit >= this.timer.uhrzeit) ||
                      (doc.data().zutritt.timestamp <= Date.now() &&
                        doc.data().verlassen.timestamp >= Date.now())) &&
                    (doc.data().sportstaette.name == this.sportstaette.name ||
                      (this.sportstaette.main && !doc.data().sportstaette))
                  ) {
                    var add = true
                    if (doc.data().veranstaltung) {
                      if (doc.data().veranstaltung.art == 'Auswärtsspiel') {
                        add = false
                      }
                    }
                    if (add) {
                      this.anwesende_personen.push(doc.data())
                      let index = this.anwesende_personen.length - 1
                      this.anwesende_personen[index].id = doc.id
                      if (!doc.data().veranstaltung) {
                        this.anwesende_personen[index].veranstaltung = {
                          id: 'ANDERE',
                          art: 'Andere',
                        }
                      }
                      if (doc.data().weitere_personen) {
                        var wp = doc.data().weitere_personen
                        wp.forEach((p) => {
                          var daten = doc.data()
                          if (p.vorname) {
                            daten.person.vorname = p.vorname
                            daten.person.name = p.nachname
                          } else {
                            var name = p.split(' ')
                            daten.person.vorname = name[0]
                            daten.person.name = name[1]
                          }

                          daten.id = doc.id
                          this.anwesende_personen.push(daten)
                        })
                      }
                      if (doc.data().person.id && doc.data().person.team) {
                        this.anwesende_member.push(
                          doc.data().person.team + '-' + doc.data().person.id,
                        )
                      }
                    }
                  }
                })
              })
          })
          .then(() => {
            setTimeout(() => {
              this.aktualisiere_personenzahl = false
            }, 1500)
          })
      }
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
    get_derzeit_anwesende_personen() {
      return this.anwesende_personen.length
    },
    async onDetect(promise) {
      try {
        const {
          imageData, // raw image data of image/frame
          content, // decoded String or null
          location, // QR code coordinates or null
        } = await promise

        if (content === null) {
          // decoded nothing
        } else {
          var daten = JSON.parse(content)
          if (daten.verein && daten.person) {
            firebase
              .firestore()
              .collection('User')
              .doc(daten.verein)
              .collection('Personen')
              .doc(daten.person)
              .get()
              .then((result) => {
                var person = result.data()
                this.zs.vorname = person.vorname
                this.zs.name = person.name
                this.zs.adresse = person.adresse
                this.zs.plz = person.plz
                this.zs.ort = person.ort
                this.zs.telefon = person.telefon
                this.qrcodedata = true
              })
          } else {
            this.qrcodedata = false
          }
        }
      } catch (error) {
        // ...
        console.log(error)
      }
    },
    async onInit(promise) {
      try {
        this.camera_loading = true
        await promise
        this.camera_loading = false
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
        this.camera_loading = false
      }
    },
    closing() {
      this.zs = {
        veranstaltung: '',
        type: '',
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
        verlassen: '',
        aktivitaet: 'Zuschauer',
        weitere_personen: [],
      }
      this.duplicate_zuschauer = {
        active: false,
        type: '',
        vorname: '',
        name: '',
      }
      this.newzuschauer = false
      this.teamselection = ''
      this.team_members = []
      this.search_members = []
      this.selected_members = []
    },
    search_person(type) {
      var searchresults = []
      for (let i = 0; i < this.anwesende_personen.length; i++) {
        if (
          this.anwesende_personen[i].person.vorname.includes(this.zs.vorname) &&
          this.anwesende_personen[i].person.name.includes(this.zs.name)
        ) {
          searchresults.push(this.anwesende_personen[i])
        }
      }
      if (searchresults.length > 0) {
        this.duplicate_zuschauer = {
          active: true,
          type: type,
          vorname: this.zs.vorname,
          name: this.zs.name,
        }
        return true
      } else {
        return false
      }
    },
    zuschauer_erfassen() {
      if (
        this.search_person() &&
        this.duplicate_zuschauer.active &&
        !this.accept_duplicate_zuschauer
      ) {
        return
      }
      if (this.zs.type == 'person') {
        if (this.zs.save_person == true) {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
          if (!isEmpty) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Personen')
              .add({
                adresse: this.zs.adresse.trim(),
                name: this.zs.name.trim(),
                vorname: this.zs.vorname.trim(),
                plz: this.zs.plz.trim(),
                ort: this.zs.ort.trim(),
                telefon: this.zs.telefon.trim(),
                aktivitaet: this.zs.aktivitaet.trim(),
                signature: data,
                ggg: this.testpflicht_required ? this.ggg : '',
              })
          } else {
            return
          }
        }
      }
      var veranstaltung = {
        id: 'ANDERE',
        art: 'Andere',
        name: 'Andere Veranstaltung',
      }
      var endzeit = {
        datum: '',
        uhrzeit: '',
      }
      if (this.zs.veranstaltung.id != 'ANDERE') {
        veranstaltung = this.zs.veranstaltung
        endzeit = new Zeit(
          this.zs.veranstaltung.ende.datum,
          this.zs.veranstaltung.ende.uhrzeit,
        )
      } else {
        endzeit = this.calc_ende()
      }
      var zutritt = new Zeit()
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .add({
          person: {
            vorname: this.zs.vorname.trim(),
            name: this.zs.name.trim(),
            adresse: this.zs.adresse.trim(),
            plz: this.zs.plz.trim(),
            ort: this.zs.ort.trim(),
            telefon: this.zs.telefon.trim(),
          },
          ggg: this.ggg || '',
          zutritt: zutritt.toJSON(),
          weitere_personen: this.zs.weitere_personen,
          verlassen: endzeit.toJSON(),
          sportstaette: this.sportstaette,
          veranstaltung: veranstaltung,
          aktivitaet: this.zs.aktivitaet,
        })
        .then((snap) => {
          this.closing()
          this.check_current_time_once()
        })
    },
  },
}
</script>
