<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
    :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : undefined"
    :overlay-color="template.colors.secondary"
    overlay-opacity="0.9"
  >
    <v-card
      style="width: 100vw; overflow-x: hidden;"
      :style="$vuetify.breakpoint.lgAndUp ? 'min-height: 50vh;' : ''"
    >
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>
          ANWESENDE PERSONEN VERWALTEN
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :color="template.colors.inline_primary_text"
          @click="
            select_search ? (select_search = false) : (select_search = true)
          "
        >
          <v-icon>
            {{ select_search ? 'mdi-magnify-close' : 'mdi-magnify' }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          :color="template.colors.inline_primary_text"
          @click="select_sort ? (select_sort = false) : (select_sort = true)"
        >
          <v-icon>mdi-sort</v-icon>
        </v-btn>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-row justify="center" v-if="select_search">
          <v-col cols="11">
            <v-text-field
              solo
              light
              hide-details
              class="rounded-xl"
              prepend-inner-icon="mdi-account-search"
              label="Suche (Vorname, Name)"
              clearable
              v-model="searchtext"
              v-on:input="search()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <v-row justify="center" v-if="select_sort">
          <v-col cols="11">
            <v-select
              label="Sortierung"
              solo
              light
              rounded
              prepend-inner-icon="mdi-sort"
              :items="sort_auswahl"
              v-model="sortierung"
              @change="sortieren()"
              class="rounded-xl mt-2"
              :hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row justify="center" class="px-4 mt-4">
        <v-col
          cols="12"
          v-for="member in filtered_persons"
          :key="member.id"
          class="py-1"
        >
          <v-card
            class="rounded-xl"
            :elevation="8"
            :color="
              selected_person == member.id
                ? template.colors.primary
                : template.colors.blocks
            "
            :style="
              selected_person == member.id
                ? `color: ${template.colors.inline_primary_text};`
                : `color: ${template.colors.block_text};`
            "
            link
          >
            <v-row
              justify="center"
              class="px-2 pb-0"
              :class="twoline ? 'text-center' : ''"
              @click="
                selected_person == member.id
                  ? (selected_person = '')
                  : (selected_person = member.id)
              "
            >
              <v-col cols="auto" align-self="center">
                <v-avatar class="my-auto" :large="twoline">
                  <v-icon
                    :style="`background-color: ${template.colors.block_text}; color: ${template.colors.blocks};`"
                  >
                    mdi-account
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col align-self="center">
                <span class="font-weight-bold text-uppercase">
                  {{ member.person.vorname + ' ' + member.person.name }}
                </span>
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-icon large :color="template.colors.block_text">
                  {{
                    selected_person == member.id
                      ? 'mdi-chevron-down'
                      : 'mdi-chevron-right'
                  }}
                </v-icon>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row justify="center" v-if="selected_person == member.id">
                <v-col cols="12" class="pb-0">
                  <v-card
                    :color="template.colors.secondary"
                    :style="`color: ${template.colors.inline_secondary_text};`"
                    class="rounded-b-xl"
                  >
                    <v-row justify="center">
                      <v-col cols="12" class="text-center">
                        <v-icon
                          :color="template.colors.inline_secondary_text"
                          large
                          class="mx-1"
                        >
                          {{
                            member.veranstaltung.art == 'Training'
                              ? 'mdi-strategy'
                              : member.veranstaltung.art == 'Wettkampf'
                              ? 'mdi-podium'
                              : member.veranstaltung.art == 'Freundschaftsspiel'
                              ? 'mdi-soccer'
                              : member.veranstaltung.art == 'Auswärtsspiel'
                              ? 'mdi-rocket-launch'
                              : member.veranstaltung.art == 'Pokalspiel'
                              ? 'mdi-trophy-variant'
                              : member.veranstaltung.art == 'Versammlung'
                              ? 'mdi-calendar-star'
                              : member.veranstaltung.art == 'Andere'
                              ? 'mdi-stadium-variant'
                              : 'mdi-soccer-field'
                          }}
                        </v-icon>
                        <h3 v-if="member.veranstaltung.art == 'Andere'">
                          Anwesenheit {{ member.sportstaette.name }}
                        </h3>
                        <h3 v-if="member.veranstaltung.art == 'Training'">
                          Training {{ member.veranstaltung.mannschaft }}
                        </h3>
                        <h3 v-if="member.veranstaltung.art == 'Wettkampf'">
                          {{ member.veranstaltung.name }}
                        </h3>
                        <h3
                          v-if="
                            member.veranstaltung.art == 'Freundschaftsspiel' ||
                            member.veranstaltung.art == 'Meisterschaftsspiel' ||
                            member.veranstaltung.art == 'Pokalspiel'
                          "
                        >
                          {{ member.veranstaltung.mannschaft }} vs
                          {{ member.veranstaltung.gastmannschaft }}
                        </h3>
                      </v-col>
                    </v-row>
                    <v-divider light></v-divider>
                    <v-row justify="center" class="text-center text-uppercase">
                      <v-col>
                        <small>CHECK-IN</small>
                        <h3>{{ member.zutritt.uhrzeit }} Uhr</h3>
                      </v-col>
                      <v-divider vertical light></v-divider>
                      <v-col>
                        <small>AUTO-CHECK-OUT</small>
                        <h3>{{ member.verlassen.uhrzeit }} Uhr</h3>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      class="text-center text-uppercase"
                      v-if="member.aktivitaet != 'Sportler'"
                    >
                      <v-col cols="12" class="py-0">
                        <v-card
                          :color="template.colors.primary"
                          :style="`color: ${template.colors.inline_primary_text};`"
                          class="rounded-b-xl pa-4 font-weight-bold"
                          @click="checkout = member.id"
                        >
                          <v-icon large class="mr-1">mdi-logout</v-icon>
                          CHECK-OUT
                        </v-card>
                        <v-dialog
                          :value="checkout == member.id"
                          persistent
                          max-width="600"
                          dark
                          :overlay-color="template.colors.primary"
                          overlay-opacity="0.9"
                          class="rounded-xl"
                          style="padding-top: env(safe-area-inset-top);"
                        >
                          <v-card
                            class="rounded-xl"
                            :color="template.colors.blocks"
                            :style="`color: ${template.colors.block_text};`"
                          >
                            <v-card-title class="headline text-uppercase">
                              CHECK-OUT: {{ member.person.vorname }}
                              {{ member.person.name }}
                            </v-card-title>
                            <v-divider light class="mt-1 mb-2"></v-divider>
                            <v-card-text
                              :style="`color: ${template.colors.block_text};`"
                            >
                              <p>
                                Check-Out Zeit:
                                <b>{{ timer.uhrzeit }} Uhr</b>
                              </p>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :color="template.colors.block_text"
                                text
                                small
                                @click="checkout = ''"
                              >
                                Abbrechen
                              </v-btn>
                              <v-btn
                                large
                                color="red"
                                @click="check_out(member)"
                              >
                                BESTÄTIGEN
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../../firebaseConfig'
import store from '../../../../store'

export default {
  name: 'Anwesende-Personen-verwalten',
  data() {
    return {
      filtered_persons: [],
      searchtext: '',
      selected_person: '',
      checkout: '',
      current_dateobj: {
        year: '',
        month: '',
        day: '',
        hours: '',
        minutes: '',
        seconds: '',
        date_formatted: '',
        time_formatted: '',
      },
      select_search: false,
      select_sort: false,
      sortierung: 'Name (aufsteigend)',
      sort_auswahl: [
        'Name (aufsteigend)',
        'Name (absteigend)',
        'Vorname (aufsteigend)',
        'Vorname (absteigend)',
      ],
    }
  },
  props: {
    personen: Array,
    veranstaltungen: Array,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    this.filtered_persons = this.personen
    this.sortieren()
  },
  methods: {
    close() {
      this.$emit('closed', true)
    },
    aktuelle_zeit() {
      var now = new Date()
    },
    check_out(member) {
      var verlassen = new Zeit('', '', Date.now())
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(member.id)
        .update({
          verlassen: {
            datum: verlassen.getDatum(),
            uhrzeit: verlassen.getUhrzeit(),
            timestamp: verlassen.getTimestamp(),
          },
        })
        .then(() => {
          this.close()
        })
    },
    search() {
      if (
        typeof this.searchtext === 'string' ||
        this.searchtext instanceof String
      ) {
        const search = this.searchtext.split(' ')
        var searchresults = []
        for (let s = 0; s < search.length; s++) {
          for (let i = 0; i < this.personen.length; i++) {
            if (this.personen[i].person.vorname.includes(search[s])) {
              searchresults.push(this.personen[i])
            } else if (this.personen[i].person.name.includes(search[s])) {
              searchresults.push(this.personen[i])
            }
          }
        }
        this.filtered_persons = []
        this.filtered_persons = searchresults
      } else {
        this.filtered_persons = []
        this.filtered_persons = this.personen
      }
    },
    sortieren() {
      function compareNameASC(a, b) {
        if (a.person.name > b.person.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.person.name > b.person.name) {
          return -1
        } else {
          return 1
        }
      }
      function compareVornameASC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return 1
        } else {
          return -1
        }
      }
      function compareVornameDESC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Name (aufsteigend)') {
        this.filtered_persons.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.filtered_persons.sort(compareNameDESC)
      }
      if (this.sortierung == 'Vorname (aufsteigend)') {
        this.filtered_persons.sort(compareVornameASC)
      }
      if (this.sortierung == 'Vorname (absteigend)') {
        this.filtered_persons.sort(compareVornameDESC)
      }
      this.select_sort = false
    },
  },
}
</script>
