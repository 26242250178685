<template>
  <v-card
    class="rounded-xl"
    :elevation="8"
    :color="abgesagt ? 'rgba(255,255,255,0.5)' : template.colors.blocks"
    :style="`color: ${template.colors.block_text}; height: 100%;`"
    link
    :loading="loading"
    @click="loading = true"
    :to="
      user.data.rolle != 'Zuschauerkontrolle'
        ? art == 'Wettkampf'
          ? `/wettkampf/${id}`
          : `/veranstaltung/${id}`
        : undefined
    "
  >
    <v-row class="px-2">
      <v-col cols="2" align-self="center">
        <v-avatar class="my-auto">
          <v-icon
            :class="abgesagt ? 'grey darken-3 error--text' : ''"
            :style="`background-color: ${template.colors.secondary}; color: ${template.colors.inline_secondary_text};`"
          >
            {{
              art == 'Training'
                ? 'mdi-strategy'
                : art == 'Wettkampf'
                ? 'mdi-podium'
                : art == 'Freundschaftsspiel'
                ? 'mdi-soccer'
                : art == 'Auswärtsspiel'
                ? 'mdi-rocket-launch'
                : art == 'Pokalspiel'
                ? 'mdi-trophy-variant'
                : art == 'Versammlung'
                ? 'mdi-calendar-star'
                : 'mdi-soccer-field'
            }}
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="8" align-self="center">
        <span :class="abgesagt ? 'text-decoration-line-through' : undefined">
          <small v-if="beginndatum">{{ parseDate(beginndatum) }}</small>
          <span v-if="beginndatum">&nbsp;|&nbsp;</span>
          <b>
            {{ beginnuhrzeit }}
            <span v-if="beginnuhrzeit ? beginnuhrzeit != 'GANZTÄGIG' : false">
              Uhr
            </span>
          </b>
          <small
            class="ml-2"
            v-if="sportstaette && $vuetify.breakpoint.mdAndUp"
          >
            <v-icon small>mdi-map-marker</v-icon>
            {{ sportstaette }}
          </small>
        </span>
        <br />
        <span class="red--text font-weight-bold font-italic">
          {{ abgesagt ? 'ABGESAGT' : '' }}
        </span>
        <span :class="abgesagt ? 'text-decoration-line-through' : undefined">
          <b>
            {{
              art == 'Training'
                ? mannschaft
                : art == 'Wettkampf' || art == 'Versammlung'
                ? name
                : art == 'Auswärtsspiel'
                ? gastgeberverein
                  ? mannschaft + ' bei ' + gastgeberverein.name
                  : gastmannschaft + ' vs ' + mannschaft
                : mannschaft + ' vs ' + gastmannschaft
            }}
          </b>
          <small v-if="gastgeberverein">({{ gastmannschaft }})</small>
          <br v-if="art != 'Training' && art != 'Wettkampf'" />
          {{ art == 'Training' || art == 'Wettkampf' ? ' - ' : '' }}
          {{ art == 'Versammlung' ? mannschaft : art }}
          <small v-if="sportstaette && $vuetify.breakpoint.smAndDown">
            <br v-if="art != 'Versammlung'" />
            <v-icon small>mdi-map-marker</v-icon>
            {{ sportstaette }}
          </small>
        </span>
      </v-col>
      <v-col cols="2" align-self="center" class="text-right">
        <v-icon large :color="template.colors.block_text">
          mdi-chevron-right
        </v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'veranstaltungscard',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    abgesagt: Boolean,
    art: String,
    gastmannschaft: String,
    gastgeberverein: Object,
    id: String,
    mannschaft: String,
    name: String,
    beginnuhrzeit: String,
    beginndatum: String,
    sportstaette: String,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
  },
}
</script>
